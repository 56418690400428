<template>
  <div>
    <div style="display: flex;justify-content: flex-end;margin: 10px 30px">
      <el-button type="primary" @click="print">打印</el-button>
    </div>
    <tinymce id="tinymce" ref="tinymceRefs" :texts="html" @changes="getChangeData"></tinymce>
    <div id="forms" style="">
      <div v-html="html">
      </div>
    </div>
  </div>
</template>

<script>
import tinymce from "@/components/tinymce";
import print from "print-js";

export default {
  name: "checknotify",
  components: {
    tinymce
  },
  data() {
    return {
      html: '<p class="MsoNormal" style="line-height: 26pt;" align="center"><span style="font-size: 14px;"><span style="font-family: SimSun; font-size: 25px;">慈溪市自然资源和规划局建设项目综合验收合格通知书</span></span></p>\n' +
          '<div style="text-align: right;" align="center"><span style="font-size: 14px; font-family: SimSun;"><span style="font-size: 14px;">编号：慈自然资规综验书<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>号&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span></div>\n' +
          '<div style="text-align: right;" align="center"><span style="font-size: 14px; font-family: SimSun;"><span style="font-size: 14px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>&nbsp; &nbsp; &nbsp;</span></div>\n' +
          '<div style="text-align: center;" align="center"><span style="text-decoration: underline; font-family: SimSun;"><span style="font-size: 14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span><span style="font-size: 14px; font-family: SimSun;">单位：&nbsp;&nbsp;</span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>\n' +
          '<p style="text-align: center;" align="center"><span style="font-size: 14px; font-family: SimSun;">&nbsp; 根据你单位提供的有关资料，在<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>街道（镇）<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>开</span></p>\n' +
          '<p style="text-align: center;" align="center"><span style="font-size: 14px; font-family: SimSun;">发建设项目现已竣工，经综合验收，符合要求，特发此通知书。</span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>\n' +
          '<table style="border-collapse: collapse; width: 81.3926%; height: 311px; margin-left: auto; margin-right: auto;font-family: SimSun;font-size: 14px" border="1">\n' +
          '<tbody>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 13.6246%; height: 21px;">\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;">地块位置</span></p>\n' +
          '</td>\n' +
          '<td style="width: 86.3357%; height: 21px;" colspan="8">&nbsp;</td>\n' +
          '</tr>\n' +
          '<tr style="height: 10px;">\n' +
          '<td style="width: 13.6246%; height: 10px;">\n' +
          '<p class="MsoNormal" style="line-height: 26pt; text-align: center;" align="center"><span style="font-size: 14px;"><span style="font-family: SimSun;">土地面积</span></span></p>\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;">（平方米）</span></p>\n' +
          '</td>\n' +
          '<td style="height: 10px; width: 35.0788%;" colspan="4">\n' +
          '<p class="MsoNormal" style="text-align: center;">&nbsp;</p>\n' +
          '</td>\n' +
          '<td style="height: 10px; width: 12.1186%; text-align: center;"><span style="font-family: SimSun; font-size: 14px; text-align: center;">土地用途</span></td>\n' +
          '<td style="width: 39.1383%;" colspan="3">&nbsp;</td>\n' +
          '</tr>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 13.6246%; height: 21px;">\n' +
          '<p class="MsoNormal" style="text-align: center; line-height: 26.0000pt; mso-line-height-rule: exactly;" align="center"><span style="font-size: 14px;"><span style="font-family: SimSun;">土地使用权</span><span style="font-family: SimSun;">类型</span></span></p>\n' +
          '</td>\n' +
          '<td style="height: 21px; width: 35.0788%;" colspan="4">\n' +
          '<p class="MsoNormal" style="text-align: center;">&nbsp;</p>\n' +
          '</td>\n' +
          '<td style="height: 21px; width: 12.1186%; text-align: center;"><span style="font-family: SimSun; font-size: 14px; text-align: center;">使用年限</span></td>\n' +
          '<td style="width: 39.1383%;" colspan="3">&nbsp;</td>\n' +
          '</tr>\n' +
          '<tr style="height: 10px;">\n' +
          '<td style="width: 13.6246%; height: 31px;" rowspan="2">\n' +
          '<p class="MsoNormal" style="line-height: 26pt; text-align: center;" align="center"><span style="font-size: 14px;"><span style="font-family: SimSun;"><span style="font-family: SimSun;">建</span> <span style="font-family: SimSun;">筑</span> <span style="font-family: SimSun;">面</span> <span style="font-family: SimSun;">积</span></span></span></p>\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;">（平方米）</span></p>\n' +
          '</td>\n' +
          '<td style="width: 86.3357%; height: 10px;" colspan="8">\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;"><span style="font-family: SimSun;">其</span> &nbsp;<span style="font-family: SimSun;">中</span></span></p>\n' +
          '</td>\n' +
          '</tr>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 8.61759%; height: 21px;">\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;">住宅</span></p>\n' +
          '</td>\n' +
          '<td style="width: 8.9767%; height: 21px;">\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;">商业</span></p>\n' +
          '</td>\n' +
          '<td style="width: 8.8869%; height: 21px;">\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;">办公</span></p>\n' +
          '</td>\n' +
          '<td style="width: 8.59764%; height: 21px;">\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;">工业</span></p>\n' +
          '</td>\n' +
          '<td style="width: 12.1186%; height: 21px;">\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;">教育科研</span></p>\n' +
          '</td>\n' +
          '<td style="width: 11.6696%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 11.0414%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 16.4273%; height: 21px;">&nbsp;</td>\n' +
          '</tr>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 13.6246%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 8.61759%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 8.9767%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 8.8869%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 8.59764%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 12.1186%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 11.6696%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 11.0414%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 16.4273%; height: 21px;">&nbsp;</td>\n' +
          '</tr>\n' +
          '<tr style="height: 10px;">\n' +
          '<td style="width: 13.6246%; height: 10px;">\n' +
          '<p class="MsoNormal" style="text-align: center;"><span style="font-family: SimSun; font-size: 14px;">备注</span></p>\n' +
          '</td>\n' +
          '<td style="width: 8.61759%; height: 10px;">&nbsp;</td>\n' +
          '<td style="width: 8.9767%; height: 10px;">&nbsp;</td>\n' +
          '<td style="width: 8.8869%; height: 10px;">&nbsp;</td>\n' +
          '<td style="width: 8.59764%; height: 10px;">&nbsp;</td>\n' +
          '<td style="width: 12.1186%; height: 10px;">&nbsp;</td>\n' +
          '<td style="width: 11.6696%; height: 10px;">&nbsp;</td>\n' +
          '<td style="width: 11.0414%; height: 10px;">&nbsp;</td>\n' +
          '<td style="width: 16.4273%; height: 10px;">&nbsp;</td>\n' +
          '</tr>\n' +
          '</tbody>\n' +
          '</table>\n' +
          '<div class="MsoNormal" style="line-height: 26pt; text-align: right;" align="center"><span style="font-size: 14px;"><span style="font-family: SimSun;">&nbsp; &nbsp; &nbsp; &nbsp; </span><span style="font-family: SimSun;">&nbsp; &nbsp;</span><span style="font-family: SimSun;">慈溪市自然资源和规划局&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span></div>\n' +
          '<div class="MsoNormal" style="line-height: 26pt; text-align: right;"><span style="font-family: SimSun; font-size: 14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span style="font-family: SimSun;">年</span> &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-family: SimSun;">月</span> &nbsp;&nbsp;&nbsp;<span style="font-family: SimSun;">日&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span></div>'
    }
  },
  methods: {
    getChangeData(res) {
      this.html = res
      console.log(res)
    },
    print() {
      this.$refs.tinymceRefs.sendData()
      setTimeout(() => {
        print({
          printable: "forms",
          type: "html",
          scanStyles: false,
          css: "/static/sjfasc.css",
          documentTitle: "",
        });
      }, 10)
    },
  }
}
</script>

<style scoped>
#forms {
  display: none;
}


</style>
